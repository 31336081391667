import React from 'react';
import './PortfolioCard.scss';

const PortfolioCard = ({ title, img, link }) => {
  return (
    <div className="PortfolioCard">
      <div className="PortfolioCard__container">
        <div className="PortfolioCard__img-container">
          <img className="PortfolioCard__img" src={img} alt={title} />
        </div>
        <div className="PortfolioCard__title">{title}</div>
        <a className="PortfolioCard__action" href={link} target="_blank">Ver más</a>

      </div>
    </div>
  );
};

export default PortfolioCard;