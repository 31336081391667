import React, { useEffect, useRef } from "react";
import "./Intro.scss";

import homeImage from "../../../img/home.png";
import textures from "../../../img/textures.png";
import logo from "../../../img/logo.png";
import videoWebm from "../../../img/cellphone.webm";
import videoMp4 from "../../../img/cellphone.mp4";

function Intro(props) {
  const videoRef = useRef();
  const videoWrapperRef = useRef();
  const canvasRef = useRef();
  let ctx;
  let vid, canvas, wrapper;  
  const ratio = window.devicePixelRatio || 1;
  let vidWidth;
  let vidHeight;

  useEffect(() => {
    vid = videoRef.current;
    canvas = canvasRef.current;
    wrapper = videoWrapperRef.current;

    manipulateVideo(canvas);
  }, [videoRef, videoWrapperRef, canvasRef]);



  const manipulateVideo = (canvas) => {
    ctx = canvas.getContext('2d');
    
    videoRef.current.play();
    videoRef.current.onloadedmetadata = function () {
      vidWidth = vid.videoWidth;
      vidHeight = vid.videoHeight;

      canvas.width = vid.offsetWidth;
      canvas.height = vid.offsetHeight;

      drawingLoop();

      setTimeout(() => {
        setVideoBgColor(vid, wrapper);
      }, 150);
    };
  };

  function drawingLoop() {
    const requestId = window.requestAnimationFrame(drawingLoop);

    ctx.drawImage(
      vid,
      0,
      0,
      vidWidth,
      vidHeight, // source rectangle
      0,
      0,
      canvas.width,
      canvas.height
    ); // destination rectangle);
  }

  function setVideoBgColor(vid, backgroundElement) {
    // draw first four pixel of video to a canvas
    // then get pixel color from that canvas
    var canvas = document.createElement("canvas");
    canvas.width = 8;
    canvas.height = 8;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(vid, 0, 0, 8, 8);

    var p = ctx.getImageData(0, 0, 8, 8).data;
    //dont take the first but fourth pixel [r g b]
    backgroundElement.style.backgroundColor =
      "rgb(" + p[60] + "," + p[61] + "," + p[62] + ")";
  }

  window.onresize = function (event) {
    vidWidth = vid.videoWidth;
    vidHeight = vid.videoHeight;

    canvas.width = vid.offsetWidth;
    canvas.height = vid.offsetHeight;

    //redraw canvas after resize
    ctx.drawImage(
      vid,
      0,
      0,
      vidWidth,
      vidHeight, // source rectangle
      0,
      0,
      canvas.width,
      canvas.height
    ); // destination rectangle);
  };

  return (
    <div  ref={videoWrapperRef} className="IntroContainer">
      <div className="IntroContainer__element">
        <canvas ref={canvasRef} id="c1" width="576" height="324"></canvas>
        <video
          ref={videoRef}
          className="IntroContainer__video"
          autoPlay
          muted
          loop
          crossOrigin="anonymous"
        >
          <source src="https://i.imgur.com/knDjbiy.mp4" type="video/mp4" />
          <source src={videoWebm} type="video/webm" />
          Your browser is not supported!
        </video>
      </div>
      <div className="IntroContainer__logo">
        <img className="IntroContainer__img" src={logo} alt="Home Image" />
      </div>
      <div className="IntroContainer__textures">
        <img
          className="IntroContainer__img"
          src={textures}
          alt="Home Texture"
        />
      </div>
    </div>
  );
}

export default Intro;
