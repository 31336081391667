import React, { useState } from 'react';
import './Contact.scss';

const Contact = () => {
  const FORM_ID = 'meqpwbav';
  const [values, setValues] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    country: '',
    comments: ''
  });

  const [status, setStatus] = useState(null);

  const handleInputChange = (e)=>{
    const {name, value} = e.target;
    setValues({...values, [name]: value});
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const form = ev.target;

    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS")
      } else {
        setStatus("ERROR")
      }
    };
    xhr.send(data);
  }

  const renderSuccess=()=> {
    if(status==='SUCCESS'){
      return (
        <div className="Contact__success">
          <h2 className="heading-fourth Contact__title">¡Gracias por tu interés en NodoLab!</h2>
          <span className="paragraph blue">Nos pondremos en contacto muy pronto.</span>
        </div>
      );
    }
    return;
  }
  return (
    
    <div className="Contact" id="sectionContact">
      <form className="Contact__form" onSubmit={handleSubmit} action={`https://formspree.io/f/${FORM_ID}`} method="post">
        <h2 className="heading-fourth Contact__title">Aqui podemos conectarnos</h2>
        <div className="Contact__formElement">
          <label htmlFor="name">Nombre</label>
          <input 
            type="text"
            id="name"
            onChange={handleInputChange}
            value={values.name}
            placeholder="Nombre"
            name="name"
            required/>
        </div>
        <div className="Contact__formElement">
          <label htmlFor="company">Empresa</label>
          <input 
            type="text"
            id="company"
            onChange={handleInputChange}
            value={values.company}
            placeholder="Empresa"
            name="company"/>
        </div>
        <div className="Contact__formElement">
          <label htmlFor="email">Email</label>
          <input 
            type="email"
            id="email"
            onChange={handleInputChange}
            value={values.email}
            placeholder="Email"
            name="email"
            required/>
        </div>
        <div className="Contact__formElement">
          <label htmlFor="phone">Teléfono</label>
          <input 
            type="number"
            id="phone"
            onChange={handleInputChange}
            value={values.phone}
            placeholder="Teléfono"
            name="phone"/>
        </div>
        <div className="Contact__formElement">
          <label htmlFor="country">País</label>
          <input 
            type="text"
            id="country"
            onChange={handleInputChange}
            value={values.country}
            placeholder="País"
            name="country"/>
        </div>
        <div className="Contact__formElement">
          <label htmlFor="country">País</label>
          <textarea 
            type="text"
            id="comments"
            onChange={handleInputChange}
            value={values.comments}
            placeholder="Comentarios"
            name="comments"
            required/>
        </div>
        <input 
          className="Contact__formSubmit" 
          type="submit" 
          value="Enviar"
          />
          {renderSuccess()}
      </form>
      <div className="Contact__info">
        <div className="Contact__infoText">
        Información <span className="Contact__infoText--bold">de contacto:</span>
        </div>
        <ul className="mediaList">
          <li className="mediaList__item">
            <ion-icon name="mail-outline"></ion-icon>
            <span className="mediaList__text">info@nodolab.co</span>   
          </li>
          <li className="mediaList__item">
            <ion-icon name="logo-whatsapp"></ion-icon>
            <span className="mediaList__text">(+57) 304 5472682</span>   
          </li>
          <li className="mediaList__item">
            <ion-icon name="logo-instagram"></ion-icon>
            <span className="mediaList__text">nodolab.co</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;