import React from 'react';
import './Teammate.scss';

const Teammate = ({name, lastname, photo, job, instagram, linkedin, behance }) => {
  return (
    <div className="Teammate">
      <div className="Teammate__photo">
        <img src={photo} alt={lastname}/>
      </div>
      <div className="Teammate__name">
        <span className="Teammate__name">{name}</span>
        <span className="Teammate__lastname">{lastname}</span>
      </div>
      <div className="Teammate__job">
        <span>{job}</span>
      </div>
      <div className="Teammate__social">
        {
          instagram ? 
          <a target="_blank" href={instagram} className="Teammate__socialItem">
            <ion-icon name="logo-instagram"></ion-icon>
          </a> : ''
        }
        {
          linkedin ?
          <a target="_blank" href={linkedin} className="Teammate__socialItem">
            <ion-icon name="logo-linkedin"></ion-icon>
          </a> : ''
        }

        {
          behance ?
          <a target="_blank" className="Teammate__socialItem">
            <ion-icon name="logo-behance"></ion-icon>
          </a> : ''
        }
        
      </div>
    </div>
  );
}

export default Teammate;