import React from 'react';
import Teammate from '../../components/molecules/Teammate/Teammate';
import './AboutUs.scss';

import photoCaro from '../../img/img21_foto_carolina.png';
import photoSebasP from '../../img/img22_foto_sebastian_p.png';
import photoSanti from '../../img/img23_foto_santiago.png';
import photoSebasQ from '../../img/img24_foto_sebastian_q.png';


const AboutUs = () => {
  return (
    <div className="aboutUs">
      <div className="aboutUs__textBlock">
        <div className="aboutUs__heading heading-sec">
          Sobre <span className="heading-sec--italic ExperiencesAdvice__heading--italic">nosotros</span>
        </div>
        <div className="aboutUs__introText">
          <p className="paragraph">Somos un colectivo <span className="paragraph--green">multidisciplinar</span>, enfocado en materializar experiencias digitales interactivas que resuelven de forma creativa las necesidades de formación en las organizaciones.</p>
          <p className="paragraph">
          Nuestro colectivo profesional está capacitado en la creación de <span className="paragraph--green">experiencias formativas virtuales</span>, cada uno desde su quehacer profesional, y unificando dichas experiencias, se gesta una estructura de trabajo que optimiza procesos en términos de calidad y tiempos.
          </p>
        </div>
      </div>
      <div className="aboutUs__teammates">
        <Teammate 
          name="Carolina"
          lastname="Llanos Tobón"
          job="Pedagogía"
          instagram="https://www.instagram.com/carollanost/"
          linkedin="https://www.linkedin.com/in/carolinallanostobon/"
          photo={photoCaro}
          >
        </Teammate>
        <Teammate 
          name="Sebastián"
          lastname="Quintero Mesa"
          job="Audiovisual"
          instagram="https://www.instagram.com/sebastianqmesa/"
          photo={photoSebasQ}
          >
        </Teammate>
        <Teammate 
          name="Sebastián"
          lastname="Paniagua Isaza"
          job="Tecnología"
          instagram="https://www.instagram.com/sebastian.paniaguai/"
          linkedin="https://linkedin.com/in/sebastián-paniagua-isaza-71b586124"
          photo={photoSebasP}
          >
        </Teammate>
        <Teammate 
          name="Santiago"
          lastname="Hernández Restrepo"
          job="Diseño"
          photo={photoSanti}
          instagram="https://www.instagram.com/santyhernandez/"
          behance="www.behance.net/santiagoh"
          >
        </Teammate>
      </div>
    </div>
  );
};

export default AboutUs;