import React from 'react'
import PortfolioCard from '../../organisms/PortfolioCard/PortfolioCard';
import './Portfolio.scss';

import routesImg from '../../../img/s4_icon1.png';
import xpImg from '../../../img/s4_icon2.png';

const Portfolio = () => {
  return (
    <div id="sectionServices">
      <div className="Portfolio" >
      <h3 className="Portfolio__heading heading-third">Servicios</h3>
      <div className="Portfolio__cards">
        <PortfolioCard link="https://www.youtube.com/watch?v=pLWxd7Et8lY" img={routesImg} title="Rutas de Formación para organizaciones"></PortfolioCard>
        <PortfolioCard link="https://www.youtube.com/watch?v=y0_wu1gxORo" img={xpImg} title="Experiencias para el aprendizaje"></PortfolioCard>
      </div>
      </div>
    </div>
  );
};

export default Portfolio;