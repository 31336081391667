import React, { useEffect } from 'react';
import Contact from '../../components/templates/Contact/Contact';
import ExperiencesAdvice from '../../components/templates/ExperiencesAdvice/ExperiencesAdvice';
import Intro from '../../components/templates/Intro/Intro';
import Portfolio from '../../components/templates/Portfolio/Portfolio';
import ServicesAdvice from '../../components/templates/ServicesAdvice/ServicesAdvice';



const Main = (props) => {
  return (
    <div id="fullpage" className="mainPage">
      <Intro className="section"></Intro>
      <ServicesAdvice className="section"></ServicesAdvice>
      <ExperiencesAdvice className="section"></ExperiencesAdvice>
      <Portfolio className="section"></Portfolio>
      <Contact className="section"></Contact>
    </div>
  );


};

export default Main;