import React, { useEffect, useRef } from 'react';
import './Header.scss';
import history from '../../../history';
import logo from '../../../img/logo.png';
import logoSchool from '../../../img/Logo_NodoSchool.svg'
import { Link } from 'react-router-dom';

function Header(props) {

  

  const toggleMenuRef = useRef(null);

  const handleMenuClick = (e, origin)=> {
    e.preventDefault();
    const URL = window.location.href.split('/').pop();
    toggleMenuRef.current.click();
    switch (origin) {
      case 'services':
        if(URL!='#') {
          console.log("Hola");
          history.push('/');
          setTimeout(()=>{scrollInto('sectionServices');}, 500);
        } else {
          console.log("Hola 2");

          scrollInto('sectionServices');
        }
        break;
      case 'contact':
        if(URL!='#') {
          console.log("Hola");
          history.push('/');
          setTimeout(()=>{scrollInto('sectionContact');}, 500);
        } else {
          console.log("Hola 2");
          scrollInto('sectionContact');
        }        
        break;
      default:
        break;
    }
    history.push('/');

  }


  const scrollInto = (view) => {
    document.getElementById(view).scrollIntoView();
  }
  return (
    <header className="Header">
      <div className="Header__logo">
        <Link to="/">
          <img className="Header__img" src={logo} alt="Logo NodoLab" />
        </Link>
      </div>
      <nav className="Header__navbar">
        <ul className="navbar">
          <li className="navbar__item"><a className="navbar__link" href="#" onClick={(e) => { handleMenuClick(e, 'services') }}>Servicios</a></li>
          <li className="navbar__item"><Link className="navbar__link" to="/about-us">Sobre nosotros</Link></li>
          <li className="navbar__item"><a className="navbar__link" href="#" onClick={(e) => { handleMenuClick(e, 'contact') }}>Contacto</a></li>
          <li className="navbar__item"><a target="_blank" className="navbar__link navbar__link--image" href="https://nodolab.co/lms"><img src={logoSchool} alt="Logo nodo school" /></a></li>
        </ul>
      </nav>
      <div className="navigation">
        <input type="checkbox" id="navi-toggle" className="navigation__checkbox" />
        <label htmlFor="navi-toggle" className="navigation__button" ref={toggleMenuRef}>
          <span className="navigation__icon">&nbsp;</span>
        </label>
        <div className="navigation__background">&nbsp;</div>

        <nav className="navigation__nav">
          <ul className="navigation__list">
            <li className="navigation__item"><a className="navigation__link" href="#" onClick={(e) => { handleMenuClick(e, 'services') }}>Servicios</a></li>
            <li className="navigation__item"><Link className="navigation__link" to="/about-us" onClick={(e)=>{toggleMenuRef.current.click();}}>Sobre nosotros</Link></li>
            <li className="navigation__item"><a className="navigation__link" href="#" onClick={(e) => { handleMenuClick(e, 'contact') }}>Contacto</a></li>
            <li className="navigation__item"><a target="_blank" className="navigation__link navigation__link--image" href="https://nodolab.co/lms"><img src={logoSchool} alt="Logo nodo school" /></a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;