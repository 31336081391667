import Header from '../src/components/organisms/Header/Header';
import Intro from '../src/components/templates/Intro/Intro';
import ServicesAdvice  from '../src/components/templates/ServicesAdvice/ServicesAdvice';
import ExperiencesAdvice from '../src/components/templates/ExperiencesAdvice/ExperiencesAdvice';

import './App.scss';
import Portfolio from './components/templates/Portfolio/Portfolio';
import Contact from './components/templates/Contact/Contact';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import Main from './pages/Main/Main';
import AboutUs from './pages/AboutUs/AboutUs';
import history from './history';

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Header className="Header"></Header>
        <Switch>
          <Route path="/" exact component={Main}/>
          <Route path="/about-us" exact component={AboutUs}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
